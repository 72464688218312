<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import { useVoixUserAuth } from '@voix/composables/useVoixUserAuth'
import { usePageStore } from '@voix/store/pageStore'
import { getCurrentUser } from '@voix/composables/queries/useQueryCurrentUser'
import { useVoixTheme } from '@voix/voix-layer/composables/useVoixTheme'
import { useDraggable } from '@vueuse/core'
import { navigateTo, onMounted, useCookie, useRoute } from '#imports'

getCurrentUser()

const { themeColorLightest, themeColorDark } = useVoixTheme()

const open = ref(false)

const canEdit = computed(() => {
  const { checkUserPermissions } = useVoixUserAuth()
  return checkUserPermissions(['Administration'])
})

const route = useRoute()
const containsVoixSyncQuery = computed(() => {
  return route.query['voix-sync'] !== undefined
})

// Navigation functions

function goToAdmin() {
  const pageStore = usePageStore()
  const path = pageStore.currentPage?.path
  navigateTo({
    name: 'voix-studio',
    query: {
      editing: path,
    },
  })
}

function goToFrontend() {
  const pageStore = usePageStore()
  const path = pageStore.currentPage?.path

  navigateTo(path, {
    open: {
      target: '_blank',
    },
  })
}

function goToDestination() {
  // If it contains the voix-sync query we are most likely on the backend
  // and we need to go to the frontend else go the backend
  if (containsVoixSyncQuery.value)
    goToFrontend()
  else
    goToAdmin()
}

function goToCopyPage() {
  const pageStore = usePageStore()
  const path = pageStore.currentPage?.path

  navigateTo(`/voix/pages/copy?slug=${path}`, {
    open: {
      target: '_blank',
    },
  })
}

function goToTranslatePage() {
  const pageStore = usePageStore()
  const path = pageStore.currentPage?.path

  navigateTo(`/voix/pages/translate?slug=${path}`, {
    open: {
      target: '_blank',
    },
  })
}

const el = ref<HTMLElement | null>(null)
const handle = ref<HTMLElement | null>(null)
const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 200

const cookiePosition: any = useCookie('voix-editor-shortcut-position')
const finalPosition = reactive({ top: cookiePosition.value?.top || '10px', left: cookiePosition.value?.left || '10px' })
const origin = ref('origin-top-left')
const internalPosition = ref('top-0 left-0')

function calculatePosition(x: number, y: number) {
  const edge = {
    top: y < window.innerHeight / 2,
    left: x < window.innerWidth / 2,
  }

  finalPosition.left = edge.left ? '10px' : `${innerWidth - 20}px`
  finalPosition.top = edge.top ? '10px' : `${innerHeight - 20}px`

  const vertPos = edge.top ? '' : '-translate-y-full'
  const horPos = edge.left ? '' : '-translate-x-full'
  internalPosition.value = `${vertPos} ${horPos}`
}

useDraggable(el, {
  initialValue: { x: innerWidth / 4.2, y: 80 },
  preventDefault: true,
  handle,

  onMove: (event) => {
    finalPosition.left = `${event.x}px`
    finalPosition.top = `${event.y}px`
  },

  onEnd: (event) => {
    calculatePosition(event.x, event.y)

    cookiePosition.value = finalPosition
  },
})

function recalculatePosition() {
  calculatePosition(Number.parseInt(finalPosition.left), Number.parseInt(finalPosition.top))
}

onMounted(() => {
  window.addEventListener('resize', recalculatePosition)
})

recalculatePosition()
</script>

<template>
  <div
    ref="el"
    class=" fixed z-[999999] bg-red-500 w-0 h-0 duration-100"
    :initial-value="{ x: 100, y: 100 }"
    storage-key="voix-editor-shortcut"
    storage-type="session"
    :style="finalPosition"
  >
    <div
      v-if="canEdit"
      class="absolute top-0 left-0 rounded flex flex-col duration-100 transition-transform"
      :class="[origin, internalPosition, {
        'w-[200px] border-2 text-gray-600 bg-gray-100 text-sm pt-8 shadow-lg': open,
        'w-8 h-8 bg-white border-gray-300 border rounded-xl flex justify-center items-center': !open,
      }]"
    >
      <button
        v-if="!open"
        class="absolute inset-0 flex justify-center items-center"
        @click="open = true"
      >
        <Icon class="w-4 h-4" name="heroicons:cog-20-solid" />
      </button>
      <button
        v-else
        class="absolute top-1 right-1"
        @click="open = false"
      >
        <Icon class="w-6 h-6 " name="heroicons:x-mark-20-solid" />
      </button>
      <div v-if="open">
        <div ref="handle" class="cursor-move">
          <Icon class="absolute top-1 left-1 w-4 h-4" name="heroicons:bars-3-20-solid" />
        </div>
        <div class="flex flex-col border-t border-gray-200 divide-y">
          <button
            class="relative z-20 flex space-x-1.5 py-2 px-3 hover:bg-black/5 "
            :class="{
              'opacity-100': open,
              'opacity-0': !open,
            }"
            @click="goToDestination"
          >
            <Icon class="w-4 h-4" name="heroicons:pencil-square-20-solid" />
            <span v-if="!containsVoixSyncQuery">Edit this page</span>
            <span v-if="containsVoixSyncQuery">View this page</span>
          </button>
          <button
            class="relative z-20 flex space-x-1.5 py-2 px-3 hover:bg-black/5 "
            :class="{
              'opacity-100': open,
              'opacity-0': !open,
            }"
            @click="goToCopyPage"
          >
            <Icon class="w-4 h-4" name="heroicons:document-duplicate" />
            <span>Copy Page</span>
          </button>
          <button
            class="relative z-20 flex space-x-1.5 py-2 px-3 hover:bg-black/5 "
            :class="{
              'opacity-100': open,
              'opacity-0': !open,
            }"
            @click="goToTranslatePage"
          >
            <Icon class="w-4 h-4" name="heroicons:language-20-solid" />
            <span>Translate Page</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.voix-admin-bg-dark {
  background-color: v-bind(themeColorDark);
}
.voix-admin-text-lightest {
  background-color: v-bind(themeColorLightest);
}
</style>
